import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/primeng";
import { ProyectoDTO } from "src/app/componentes-comunes/componentes-comunes.dto";
import { ComponentesComunesService } from "src/app/componentes-comunes/componentes-comunes.service";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import {
  TramiteAmbientalDTO,
  UnidadFuncionalDTO,
  JustificacionModificacionDTO,
  AmbMaEntidadAmbientalDTO,
  AmbMaTipoTramiteDTO,
  AmbMaPriorizacionDTO,
  AmbMaEstadoCompromisoDTO,
  TrnMaEtapaProyectoDTO,
  AmbMaTramiteRelacionadoDTO,
} from "../tramites-ambientales-carretero.dto.js";
import { forkJoin } from "rxjs";
import { TramitesAmbientalesService } from "../tramites-ambientales-carretero.service.js";
import { AmbMaTipoCompensacionDTO } from "src/app/compensaciones-ambientales/compensaciones-ambientales.dto.js";
import { EnumAmbMaTipoTramite, EnumAmbMaTramiteRelacionado, EnumRespuestaServicio, EnumTrnMaTipoProyecto } from "src/app/comun/constantes/constantes-comunes.js";
import { UnidadFuncionalCarreteroService } from "src/app/informacion-general-carretero/alcance-proyecto/unidad-funcional-carretero/unidad-funcional-carretero.service";
import { UnidadFuncional } from "src/app/unidad-funcional-carretero/unidadFuncional.js";


@Component({
  selector: "app-tramites-ambientales-carretero",
  templateUrl: "./tramites-ambientales-carretero.component.html",
  styleUrls: ["./tramites-ambientales-carretero.component.sass"],
})
export class TramitesAmbientalesCarreteroComponent implements OnInit {
  entidadesAmbientales: AmbMaEntidadAmbientalDTO[] = [];
  tiposTramite: AmbMaTipoCompensacionDTO[] = [];
  tramitesRelacionados: AmbMaTipoCompensacionDTO[] = [];
  priorizacionTramites: AmbMaPriorizacionDTO[] = [];
  estadosTramites: AmbMaEstadoCompromisoDTO[] = [];
  listaEtapasProyecto: TrnMaEtapaProyectoDTO[];
  tramiteAmbiental: TramiteAmbientalDTO;
  loading: boolean;
  idProyecto: number;
  idTramiteAmbiental: number;
  proyectoSeleccionado: ProyectoDTO;
  formTramiteAmbiental;
  formUnidadFuncionalAsociado;
  formJustificacionModificacion;

  nombreProyecto;
  tipoSolicitudTA: string;
  tipoSolicitudStr: string;

  unidadFuncional: UnidadFuncional;
  unidadesFuncionales: UnidadFuncional[];
  unidadesFuncionalesAsociadasList: UnidadFuncionalDTO[];
  subtramos: any[];
  subtramo?: any;
  unidadFuncionalAsociada: UnidadFuncionalDTO;
  justificacionModificacion: JustificacionModificacionDTO;
  isUnidadFuncionalEdit: boolean;

  dialogoUnidadFuncionalEliminar: boolean;
  dialogoUnidadFuncional: boolean;
  dialogoGuardarTramiteAmbiental: boolean;
  dialogoJustificacionModificacion: boolean;
  isView: boolean;
  isEdit: boolean;
  habilitaTramitesRelacionados: boolean;
  habilitaOtroTramitesRelacionados: boolean;
  habilitaPermisoTramiteRelacionado: boolean;
  habilitaEnFirme: boolean;
  dialogoExitoTramite: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private componentesComunesService: ComponentesComunesService,
    private principalComponent: PrincipalComponent,
    private tramitesAmbientalesService: TramitesAmbientalesService,
    private unidadFuncionalCarreteroService: UnidadFuncionalCarreteroService
  ) {
    // Crear formulario tramite ambiental
    this.formTramiteAmbiental = formBuilder.group({
      entidadAmbiental: [null, [Validators.required]],
      tipoTramite: [null, [Validators.required]],
      tramiteRelacionadoFormAtributte: [null],
      otroTramiteRelacionadoFormAtributte: [null],
      tramiteRelacionadoCualFormAtributte: [null],
      etapaProyectoFormAtributte: [null, [Validators.required]],
      priorizacionFormAtributte: [null, [Validators.required]],
      estadoTramiteFormAtributte: [null, [Validators.required]],
      radicadoConcesionarioFormAtributte: [null, [Validators.required]],
      fechaRadicadoConcesionarioFormAtributte: [null, [Validators.required]],
      radicadoEntidadFormAtributte: [
        null,
        [Validators.maxLength(14), Validators.required],
      ],
      fechaEntidadFormAtributte: [null, [Validators.required]],
      expedienteFormAtributte: [
        null,
        [Validators.maxLength(10), Validators.required],
      ],
      descripcionFormAtributte: [
        null,
        [
          Validators.maxLength(100),
          Validators.minLength(5),
          Validators.required,
        ],
      ],
      tramiteFirmeFormAtributte: [null, null],
    });

    // Crear formulario unidad funcional
    this.formUnidadFuncionalAsociado = formBuilder.group({
      unidadFuncionalFormAtributte: [null, [Validators.required]],
      tramoTramiteFormAtributte: [null, null],
    });

    this.formJustificacionModificacion = formBuilder.group({
      fechaActuacionFormAtributte: [null, [Validators.required]],
      observacionesFormAtributte: [
        null,
        [
          Validators.maxLength(100),
          Validators.minLength(5),
          Validators.required,
        ],
      ],
    });
  }

  ngOnInit() {
    this.habilitaTramitesRelacionados = true;
    this.loading = true;
    this.tramiteAmbiental = {};
    this.tramiteAmbiental.entidadDTO = {};
    this.tramiteAmbiental.tipoTramiteDTO = {};
    this.tramiteAmbiental.tramiteRelacionadoDTO = {};
    this.tramiteAmbiental.etapaProyectoDTO = {};
    this.tramiteAmbiental.priorizacionDTO = {};
    this.tramiteAmbiental.estadoDTO = {};
    this.unidadFuncionalAsociada = {};
    this.justificacionModificacion = {};
    this.tipoSolicitudTA = localStorage.getItem("tipoSolicitudTACarretero");
    if (this.tipoSolicitudTA === "onNew") {
      this.tipoSolicitudStr = "Modo Creación";
      this.formTramiteAmbiental.enable();
      this.formUnidadFuncionalAsociado.enable();
      this.formJustificacionModificacion.disable();
      this.isView = true;
      this.isEdit = false;
      this.cargarMaEstadoTramitesActivos();
    }
    if (this.tipoSolicitudTA === "onView") {
      this.tipoSolicitudStr = "Modo Consultar";
      this.formTramiteAmbiental.disable();
      this.formUnidadFuncionalAsociado.disable();
      this.formJustificacionModificacion.disable();
      this.isView = false;
      this.habilitaTramitesRelacionados = true;
      this.isEdit = false;
      this.habilitaEnFirme = true;
    }
    if (this.tipoSolicitudTA === "onEdit") {
      this.tipoSolicitudStr = "Modo Edición";
      this.formTramiteAmbiental.enable();
      this.formUnidadFuncionalAsociado.enable();
      this.formJustificacionModificacion.enable();
      this.isView = true;
      this.isEdit = true;
      this.habilitaEnFirme = true;
    }
    const idParamProyecto = "idProyecto";
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParamProyecto];
    });
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto),
    ).subscribe(
      ([proyectoData, lstTramites]) => {
        this.proyectoSeleccionado = proyectoData;
        this.loading = false;
      },
      (e) => { }
    );
    const idParamTramite = "idTramite";
    this.ruta.params.subscribe((params) => {
      this.idTramiteAmbiental = params[idParamTramite];
    });

    if (this.idTramiteAmbiental != 0) {
      console.log(this.idTramiteAmbiental);
      if (this.tipoSolicitudTA === "onViewHistorico") {
        this.tipoSolicitudStr = "Modo Consultar";
        this.formTramiteAmbiental.disable();
        this.formUnidadFuncionalAsociado.disable();
        this.formJustificacionModificacion.disable();
        this.isView = false;
        this.habilitaTramitesRelacionados = true;
        this.isEdit = false;
        this.habilitaEnFirme = true;
        this.cargarTramiteAmbientalPorIdHistorico();
        this.cargarMaEstadoTramites();
      } else {
        this.cargarTramiteAmbientalPorId();
        this.cargarMaEstadoTramitesActivos();
      }

    } else {

      this.unidadesFuncionalesAsociadasList = [];
      /* this.dialogoJustificacionModificacion = false;
      this.isEdit = false;
      this.tipoSolicitudStr = "";
      this.dialogoUnidadFuncionalEliminar = false;
      this.dialogoGuardarTramiteAmbiental = false;

      this.ruta.params.subscribe((params) => {
        this.idTramiteAmbiental = params.id;
        // Inicializar listas parametricas y DTOs
        forkJoin(
          this.componentesComunesService.obtenerProyecto(this.idProyecto)
        ).subscribe(
          ([proyectoData]) => {
            this.proyectoSeleccionado = proyectoData;
          },
          (e) => { }
        );



      }); */
    }
    this.cargarMaTramiteRelacionado();
    this.cargarMaPriorizacionTramiteAmbiental();
    
    this.cargarMaEtapaProyecto();
    this.cargarUnidadesFuncionalesProyecto(null);
    this.loading = false;
  }

  /**
   * Metodo que consulta la información parametrica de las entidades ambientales
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarMaEntidadAmbiental(event) {
    const filtroEntidadAmbiental = [];
    return this.tramitesAmbientalesService
      .srvConsultarMaEntidadAmbiental()
      .subscribe((result) => {
        this.entidadesAmbientales = result.listaAmbMaEntidadAmbiental;

        if (this.entidadesAmbientales === null) {
          this.entidadesAmbientales = [];
        }

        if (this.tramiteAmbiental.idEntidad != null) {
          this.tramiteAmbiental.entidadDTO = this.entidadesAmbientales.find(
            (item) => item.id == this.tramiteAmbiental.idEntidad
          );
          this.formTramiteAmbiental
            .get("entidadAmbiental")
            .setValue(this.tramiteAmbiental.entidadDTO);
        }

        if (event != null) {
          let existeFiltro: boolean;
          for (let i = 0; i < this.entidadesAmbientales.length; i++) {
            const filtro = this.entidadesAmbientales[i];
            if (
              String(filtro.nombre)
                .toLowerCase()
                .includes(event.query.toLowerCase())
            ) {
              filtroEntidadAmbiental.push(filtro);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.entidadesAmbientales = filtroEntidadAmbiental;
          }
        }
      });
  }

  /**
   * Metodo que controla el evento al momento de seleccionar una entidad ambiental
   * @param tipoCierre
   */
  onSelectEntidadAmbiental(entidadAmbiental: AmbMaEntidadAmbientalDTO) {
    if (entidadAmbiental != null && entidadAmbiental.id != null) {
      this.tramiteAmbiental.idEntidad = entidadAmbiental.id;
    }
  }

  /**
   * Metodo que consulta la información parametrica de los tipos de tramites
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarMaTipoTramite(event) {
    const filtroTipoTramite = [];
    return this.tramitesAmbientalesService
      .srvConsultarMaTipoTramite()
      .subscribe((result) => {
        this.tiposTramite = result.listaAmbMaTipoTramite;

        if (this.tiposTramite === null) {
          this.tiposTramite = [];
        }

        if (this.tramiteAmbiental.idEntidad != null) {
          this.tramiteAmbiental.tipoTramiteDTO = this.tiposTramite.find(
            (item) => item.id == this.tramiteAmbiental.idTipoTramite
          );
          this.formTramiteAmbiental
            .get("tipoTramite")
            .setValue(this.tramiteAmbiental.tipoTramiteDTO);
        }

        if (event != null) {
          let existeFiltro: boolean;
          for (let i = 0; i < this.tiposTramite.length; i++) {
            const filtro = this.tiposTramite[i];
            if (
              String(filtro.nombre)
                .toLowerCase()
                .includes(event.query.toLowerCase())
            ) {
              filtroTipoTramite.push(filtro);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.tiposTramite = filtroTipoTramite;
          }
        }
      });
  }

  /**
   * Metodo que controla el evento al momento de seleccionar un Tipo de tramite
   * @param tipoTramite
   */
  onSelectTipoTramite(tipoTramite: AmbMaTipoTramiteDTO) {
    if (tipoTramite != null && tipoTramite.id != null) {
      this.tramiteAmbiental.idTipoTramite = tipoTramite.id;
      if (EnumAmbMaTipoTramite.TIPO_TRAMITE_CESION === tipoTramite.id || EnumAmbMaTipoTramite.TIPO_TRAMITE_CIERRE_ARCHIVO === tipoTramite.id
        || EnumAmbMaTipoTramite.TIPO_TRAMITE_DESISTIMIENTO === tipoTramite.id || EnumAmbMaTipoTramite.TIPO_TRAMITE_MODIFICACION === tipoTramite.id) {
        this.habilitaTramitesRelacionados = false;        
        if (EnumAmbMaTipoTramite.TIPO_TRAMITE_DESISTIMIENTO === tipoTramite.id) {
          this.habilitaOtroTramitesRelacionados = true;
        }
      } else {
        if (EnumAmbMaTipoTramite.TIPO_TRAMITE_OTRO === tipoTramite.id) {
          this.habilitaOtroTramitesRelacionados = true;
        } else {
          this.habilitaOtroTramitesRelacionados = false;
          this.tramiteAmbiental.tipoTramiteCual = null;
        }
        this.tramiteAmbiental.tramiteRelacionadoDTO = null;
        this.tramiteAmbiental.idTramiteRelacionado = null;
        this.habilitaTramitesRelacionados = true;
        this.habilitaPermisoTramiteRelacionado = false;
        this.tramiteAmbiental.tramiteRelacionadoCual = null;
      }
    }
  }

  /**
   * Metodo que controla el evento al momento de seleccionar un Tramite relacionado
   * @param tramiteRelacionado 
   */
  onSelectTramiteRelacionado(tramiteRelacionado) {
    console.log(tramiteRelacionado);
    if (tramiteRelacionado.value.id === EnumAmbMaTramiteRelacionado.TRAMITE_RELACIONADO_PERMISO) {
      this.habilitaPermisoTramiteRelacionado = true;
    } else {
      this.habilitaPermisoTramiteRelacionado = false;
    }
  }

  /**
   * Metodo que consulta la información parametrica de los tramites relacionados
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarMaTramiteRelacionado() {
    return this.tramitesAmbientalesService
      .srvConsultarMaTramiteRelacionado()
      .subscribe((result) => {
        this.tramitesRelacionados = result.listaAmbMaTramiteRelacionado;

        if (this.tramitesRelacionados === null) {
          this.tramitesRelacionados = [];
        }

        if (this.tramiteAmbiental.idTramiteRelacionado != null) {
          this.tramiteAmbiental.tramiteRelacionadoDTO = this.tramitesRelacionados.find(
            (item) => item.id == this.tramiteAmbiental.idTramiteRelacionado
          );
          this.formTramiteAmbiental
            .get("tramiteRelacionadoFormAtributte")
            .setValue(this.tramiteAmbiental.tramiteRelacionadoDTO);
        }
      });
  }

  /**
   * Metodo que consulta la información parametrica de los tramites relacionados
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarMaEtapaProyecto() {
    return this.tramitesAmbientalesService
      .srvConsultarMaEtapaProyecto(EnumTrnMaTipoProyecto.MODO_CARRETERO)
      .subscribe((result) => {
        this.listaEtapasProyecto = result.listaEtapasProyecto;

        if (this.listaEtapasProyecto === null) {
          this.listaEtapasProyecto = [];
        }

        if (this.tramiteAmbiental.idEtapaProyecto != null) {
          this.tramiteAmbiental.etapaProyectoDTO = this.listaEtapasProyecto.find(
            (item) => item.id == this.tramiteAmbiental.idEtapaProyecto
          );
          this.formTramiteAmbiental
            .get("etapaProyectoFormAtributte")
            .setValue(this.tramiteAmbiental.etapaProyectoDTO);
        }
      });
  }

  /**
   * Metodo que consulta las unidades funcionales por proyecto
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarUnidadesFuncionalesProyecto(event) {
    const filtroUnidadFuncional = [];
    return this.unidadFuncionalCarreteroService
      .getUnidadesFuncionalesPorSoloProyecto(this.idProyecto)
      .subscribe((result) => {
        this.unidadesFuncionales = result.unidadesFuncionales;

        if (this.unidadesFuncionales === null) {
          this.unidadesFuncionales = [];
        }

        if (event != null) {
          let existeFiltro: boolean;
          for (let i = 0; i < this.unidadesFuncionales.length; i++) {
            const filtro = this.unidadesFuncionales[i];
            if (
              String(filtro.nombre)
                .toLowerCase()
                .includes(event.query.toLowerCase())
            ) {
              filtroUnidadFuncional.push(filtro);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.unidadesFuncionales = filtroUnidadFuncional;
          }
        }


      });
  }

  /**
   * Metodo que controla el evento al momento de seleccionar una unidad funcional
   * @param tipoCierre
   */
  onSelectUnidadFuncional(unidadFuncional: UnidadFuncional) {
    if (unidadFuncional != null && unidadFuncional.id != null) {
      console.log(unidadFuncional);
      this.cargarSubtramosPorUnidadFuncional(unidadFuncional.id);
    }
  }

  cargarSubtramosPorUnidadFuncional(idUnidadFuncional) {
    this.loading = true;
    forkJoin(
      this.unidadFuncionalCarreteroService.getListarSubtramosPorUnidadFuncional(
        idUnidadFuncional, false
      )
    ).subscribe(
      ([result]) => {
        // console.log(result);

        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            this.subtramos = result.subtramos;
            console.log(this.subtramos);

          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: result.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        } else {
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: error,
          life: 10000,
        });
      },
      () => {
        //Desbloquear pantalla
        this.loading = false;
      }
    );
  }

  /**
   * Metodo para asociar la unidad funcional y subtramo al tramite
   */
  public submitAgregarUnidadFuncional() {
    let unidadFuncionalAsociada: UnidadFuncionalDTO = {};
    unidadFuncionalAsociada.idUnidadFuncional = this.unidadFuncional.idUnidadFuncional;
    unidadFuncionalAsociada.codigoUnidadFuncional = this.unidadFuncional.codigo;
    unidadFuncionalAsociada.nombreUnidadFuncional = this.unidadFuncional.nombre;
    unidadFuncionalAsociada.idSubtramo = this.subtramo.idSubtramo;
    unidadFuncionalAsociada.nombreSubtramo = this.subtramo.nombre;
    this.unidadesFuncionalesAsociadasList.push(unidadFuncionalAsociada);
    this.dialogoUnidadFuncional = false;
    this.lanzarMensajeInfo("Se agrega la unidad funcional correctamente.");
  }



  /**
   * Metodo que consulta la información parametrica de priorización de los tramites ambientales
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarMaPriorizacionTramiteAmbiental() {
    return this.tramitesAmbientalesService
      .srvConsultarMaPriorizacion()
      .subscribe((result) => {
        this.priorizacionTramites = result.listaAmbMaPriorizacion;

        if (this.priorizacionTramites === null) {
          this.priorizacionTramites = [];
        }

        if (this.tramiteAmbiental.idPriorizacion != null) {
          this.tramiteAmbiental.priorizacionDTO = this.priorizacionTramites.find(
            (item) => item.id == this.tramiteAmbiental.idPriorizacion
          );
          this.formTramiteAmbiental
            .get("priorizacionFormAtributte")
            .setValue(this.tramiteAmbiental.priorizacionDTO);
        }
      });
  }

  /**
   * Metodo que consulta la información parametrica de priorización de los tramites ambientales
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarMaEstadoTramites() {
    return this.tramitesAmbientalesService
      .srvConsultarMaEstadoCompromiso()
      .subscribe((result) => {
        this.estadosTramites = result.listaAmbMaEstadoCompromiso;

        if (this.estadosTramites === null) {
          this.estadosTramites = [];
        }

        if (this.tramiteAmbiental.idEstadoTramite != null) {
          this.tramiteAmbiental.estadoDTO = this.estadosTramites.find(
            (item) => item.id == this.tramiteAmbiental.idEstadoTramite
          );
          this.formTramiteAmbiental
            .get("estadoTramiteFormAtributte")
            .setValue(this.tramiteAmbiental.estadoDTO);
        }
      });
  }

  /**
   * Metodo que consulta la información parametrica de priorización de los tramites ambientales
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarMaEstadoTramitesActivos() {
    return this.tramitesAmbientalesService
      .srvConsultarMaEstadoCompromisoActivos()
      .subscribe((result) => {
        this.estadosTramites = result.listaAmbMaEstadoCompromiso;

        if (this.estadosTramites === null) {
          this.estadosTramites = [];
        }

        if (this.tramiteAmbiental.idEstadoTramite != null) {
          this.tramiteAmbiental.estadoDTO = this.estadosTramites.find(
            (item) => item.id == this.tramiteAmbiental.idEstadoTramite
          );
          this.formTramiteAmbiental
            .get("estadoTramiteFormAtributte")
            .setValue(this.tramiteAmbiental.estadoDTO);
        }
      });
  }

  /**
   * Metodo que consulta la información del tramite ambiental por id
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarTramiteAmbientalPorId() {
    this.loading = true;
    return this.tramitesAmbientalesService
      .srvConsultarTramiteAmbientalPorId(this.idTramiteAmbiental)
      .subscribe((result) => {
        this.tramiteAmbiental = result.tramiteAmbiental;
        console.log(this.tramiteAmbiental);

        if (this.tramiteAmbiental.fechaRadicado != null) {
          this.tramiteAmbiental.fechaRadicado = new Date(this.tramiteAmbiental.fechaRadicado);
        }

        if (this.tramiteAmbiental.fechaRadicadoEntidad != null) {
          this.tramiteAmbiental.fechaRadicadoEntidad = new Date(this.tramiteAmbiental.fechaRadicadoEntidad);
        }



        this.dialogoJustificacionModificacion = false;
        this.dialogoUnidadFuncionalEliminar = false;
        this.dialogoGuardarTramiteAmbiental = false;

        this.cargarMaEntidadAmbiental(null);
        this.cargarMaTipoTramite(null);
        this.cargarMaTramiteRelacionado();
        this.cargarMaEtapaProyecto();
        this.cargarMaPriorizacionTramiteAmbiental();
        this.cargarMaEstadoTramites();
        this.cargarUnidadesFuncionales();
        if (this.tramiteAmbiental.idTipoTramite != null) {
          if (EnumAmbMaTipoTramite.TIPO_TRAMITE_CESION === this.tramiteAmbiental.idTipoTramite || EnumAmbMaTipoTramite.TIPO_TRAMITE_CIERRE_ARCHIVO === this.tramiteAmbiental.idTipoTramite
            || EnumAmbMaTipoTramite.TIPO_TRAMITE_DESISTIMIENTO === this.tramiteAmbiental.idTipoTramite || EnumAmbMaTipoTramite.TIPO_TRAMITE_MODIFICACION === this.tramiteAmbiental.idTipoTramite) {
            this.habilitaTramitesRelacionados = false;
            if (EnumAmbMaTipoTramite.TIPO_TRAMITE_DESISTIMIENTO === this.tramiteAmbiental.idTipoTramite) {
              this.habilitaOtroTramitesRelacionados = true;
            }
          } else {
            if (EnumAmbMaTipoTramite.TIPO_TRAMITE_OTRO === this.tramiteAmbiental.idTipoTramite) {
              this.habilitaOtroTramitesRelacionados = true;
            } else {
              this.habilitaOtroTramitesRelacionados = false;
            }
            this.tramiteAmbiental.tramiteRelacionadoDTO = null;
            this.habilitaTramitesRelacionados = true;
          }
        }

        if (this.tramiteAmbiental.idTramiteRelacionado === EnumAmbMaTramiteRelacionado.TRAMITE_RELACIONADO_PERMISO) {
          this.habilitaPermisoTramiteRelacionado = true;
        } else {
          this.habilitaPermisoTramiteRelacionado = false;
        }

        if (this.tramiteAmbiental === null) {
          this.tramiteAmbiental = {};
        }

        if (this.tipoSolicitudTA === "onView") {
          this.habilitaTramitesRelacionados = true;
        }

        this.loading = false;
      });
  }

  /**
   * Metodo que consulta la información del tramite ambiental por id
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarTramiteAmbientalPorIdHistorico() {
    this.loading = true;
    return this.tramitesAmbientalesService
      .srvConsultarTramiteAmbientalPorIdHistorico(this.idTramiteAmbiental)
      .subscribe((result) => {
        this.tramiteAmbiental = result.tramiteAmbiental;
        console.log(this.tramiteAmbiental);

        if (this.tramiteAmbiental.fechaRadicado != null) {
          this.tramiteAmbiental.fechaRadicado = new Date(this.tramiteAmbiental.fechaRadicado);
        }

        if (this.tramiteAmbiental.fechaRadicadoEntidad != null) {
          this.tramiteAmbiental.fechaRadicadoEntidad = new Date(this.tramiteAmbiental.fechaRadicadoEntidad);
        }



        this.dialogoJustificacionModificacion = false;
        this.dialogoUnidadFuncionalEliminar = false;
        this.dialogoGuardarTramiteAmbiental = false;

        this.cargarMaEntidadAmbiental(null);
        this.cargarMaTipoTramite(null);
        this.cargarMaTramiteRelacionado();
        this.cargarMaEtapaProyecto();
        this.cargarMaPriorizacionTramiteAmbiental();
        this.cargarMaEstadoTramites();
        this.cargarUnidadesFuncionales();
        if (this.tramiteAmbiental.idTipoTramite != null) {
          if (EnumAmbMaTipoTramite.TIPO_TRAMITE_CESION === this.tramiteAmbiental.idTipoTramite || EnumAmbMaTipoTramite.TIPO_TRAMITE_CIERRE_ARCHIVO === this.tramiteAmbiental.idTipoTramite
            || EnumAmbMaTipoTramite.TIPO_TRAMITE_DESISTIMIENTO === this.tramiteAmbiental.idTipoTramite || EnumAmbMaTipoTramite.TIPO_TRAMITE_MODIFICACION === this.tramiteAmbiental.idTipoTramite) {
            this.habilitaTramitesRelacionados = false;
            if (EnumAmbMaTipoTramite.TIPO_TRAMITE_DESISTIMIENTO === this.tramiteAmbiental.idTipoTramite) {
              this.habilitaOtroTramitesRelacionados = true;
            }
          } else {
            if (EnumAmbMaTipoTramite.TIPO_TRAMITE_OTRO === this.tramiteAmbiental.idTipoTramite) {
              this.habilitaOtroTramitesRelacionados = true;
            } else {
              this.habilitaOtroTramitesRelacionados = false;
            }
            this.tramiteAmbiental.tramiteRelacionadoDTO = null;
            this.habilitaTramitesRelacionados = true;
          }
        }

        if (this.tramiteAmbiental.idTramiteRelacionado === EnumAmbMaTramiteRelacionado.TRAMITE_RELACIONADO_PERMISO) {
          this.habilitaPermisoTramiteRelacionado = true;
        } else {
          this.habilitaPermisoTramiteRelacionado = false;
        }

        if (this.tramiteAmbiental === null) {
          this.tramiteAmbiental = {};
        }

        if (this.tipoSolicitudTA === "onView") {
          this.habilitaTramitesRelacionados = true;
        }

        this.loading = false;
      });
  }

  /**
   * Metodo que consulta la información de unidades funcionales por tramite
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarUnidadesFuncionales() {
    return this.tramitesAmbientalesService
      .srvListarUnidadesFuncionalesPorIdTramite(this.idTramiteAmbiental)
      .subscribe((result) => {
        this.unidadesFuncionalesAsociadasList = result.listaUnidadesFuncionales;

        if (this.unidadesFuncionalesAsociadasList === null) {
          this.unidadesFuncionalesAsociadasList = [];
        }


      });
  }

  /**
   * metodo que muestra la consulta de la unidad funcional
   * @param unidadFuncionalSelected
   */
  btnConsultarUnidadFuncional(unidadFuncionalSelected: UnidadFuncionalDTO) {
    //TODO: agregar logica consultar
  }

  /**
   * metodo que muestra la consulta de la unidad funcional
   * @param unidadFuncionalSelected
   */
  btnEditarUnidadFuncional(unidadFuncionalSelected: UnidadFuncionalDTO) {
    //TODO: agregar logica editar
  }

  /**
   * metodo que elimina la unidad funcional seleccionada
   * @param unidadFuncionalSelected
   */
  btnEliminarUnidadFuncional(unidadFuncionalSelected: UnidadFuncionalDTO) {
    let indice: number = this.unidadesFuncionalesAsociadasList.indexOf(unidadFuncionalSelected);
    this.unidadesFuncionalesAsociadasList.splice(indice, 1);
    this.dialogoUnidadFuncionalEliminar = true;
    this.lanzarMensajeInfo("Se elimina la unidad funcional correctamente.");
  }

  /**
   * metodo que devuelve la pagina
   */
  bntVolver() {
    const ruta =
      "carreteros/infGeneral/proyectoHome/" +
      this.idProyecto +
      "/tramites-carretero-listado/" +
      this.idProyecto;
    this.router.navigate([ruta]);
  }

  /**
   * Metodo que realiza el guardado
   */
  guardarTramiteAmbiental() {
    this.dialogoJustificacionModificacion = false;
    if (this.tipoSolicitudTA === "onNew") {
      if (this.formTramiteAmbiental.valid) {
        this.dialogoGuardarTramiteAmbiental = true;
        this.submitGuardarTramiteAmbiental();
      } else {
        this.lanzarMensajeWarning(
          "Debe diligenciar los campos requeridos marcados con asterisco (*)"
        );
      }
    } else {
      if (this.tipoSolicitudTA === "onEdit") {
        this.dialogoJustificacionModificacion = true;
        this.tramiteAmbiental.fechaActuacion = null;
        this.tramiteAmbiental.observaciones = null;
      }
    }
  }

  /**
   * Metodo que guarda la justificacion de la modificacion
   */
  submitJustificacionModificacion() {
    if (this.formJustificacionModificacion.valid) {
      this.dialogoJustificacionModificacion = false;
      //TODO: invocar servicio en la integracion
      this.submitGuardarTramiteAmbiental();
    } else {
      this.lanzarMensajeWarning(
        "Debe diligenciar los campos requeridos marcados con asterisco (*)"
      );
    }
  }

  /**
   * Metodo que realiza el guardado
   */
  submitGuardarTramiteAmbiental() {
    //TODO: agregar logica de guardado
    this.loading = true;
    this.tramiteAmbiental.idProyecto = this.idProyecto;
    this.tramiteAmbiental.nombreEntidad = this.tramiteAmbiental.entidadDTO.nombre;
    this.tramiteAmbiental.idTipoTramite = this.tramiteAmbiental.tipoTramiteDTO.id;
    this.tramiteAmbiental.nombreTipoTramite = this.tramiteAmbiental.tipoTramiteDTO.nombre;
    if (this.tramiteAmbiental.tramiteRelacionadoDTO != null) {
      this.tramiteAmbiental.idTramiteRelacionado = this.tramiteAmbiental.tramiteRelacionadoDTO.id;
      this.tramiteAmbiental.nombreTramiteRelacionado = this.tramiteAmbiental.tramiteRelacionadoDTO.nombre;
    }
    this.tramiteAmbiental.idEtapaProyecto = this.tramiteAmbiental.etapaProyectoDTO.id;
    this.tramiteAmbiental.etapaProyecto = this.tramiteAmbiental.etapaProyectoDTO.nombre;
    this.tramiteAmbiental.idEstadoTramite = this.tramiteAmbiental.estadoDTO.id;
    this.tramiteAmbiental.estadoTramite = this.tramiteAmbiental.estadoDTO.nombre;
    this.tramiteAmbiental.idPriorizacion = this.tramiteAmbiental.priorizacionDTO.id;
    this.tramiteAmbiental.nombrePriorizacion = this.tramiteAmbiental.priorizacionDTO.nombre;
    this.tramiteAmbiental.unidadesFuncionales = [];
    this.unidadesFuncionalesAsociadasList.forEach(element => {
      let unidad: UnidadFuncionalDTO = {};
      unidad.idUnidadFuncional = element.idUnidadFuncional;
      unidad.idSubtramo = element.idSubtramo;
      this.tramiteAmbiental.unidadesFuncionales.push(unidad);
    });
    console.log(this.tramiteAmbiental);

    this.tramitesAmbientalesService.srvGuardarTramiteAmbiental(this.tramiteAmbiental).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            this.dialogoExitoTramite = true;
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.loading = false;
      }
    );
  }



  /**
   * Metodo que realiza la opcionde agregar la unidad funcional
   */
  btnAgregarUnidadFuncional() {
    //TODO: agregar logica de agregar unidad funcional
    this.dialogoUnidadFuncional = true;
    this.isUnidadFuncionalEdit = true;
    this.unidadFuncional = {};
    this.subtramo = {};
  }

  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
